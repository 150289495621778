.actionButton {
	cursor: pointer;
	padding: 10px;

}

.actionButton:hover {
	background-color: #f2f2f2;
}

.editor-text-underline {
	text-decoration: underline;
}


.editor-text-bold {
	font-weight: bold;
}

.editor-text-italic {
	font-style: italic;
}

.editor-heading-h1 {
	font-size: 24px;
	color: rgb(5, 5, 5);
	font-weight: 400;
	margin: 0;
	margin-bottom: 12px;
	padding: 0;
	font-family:  'Nunito, Mada-Regular, sans-serif'
}

.editor-heading-h2 {
	font-size: 20px;
	color: rgb(5, 5, 5);
	font-weight: 400;
	margin: 0;
	margin-bottom: 12px;
	padding: 0;
	font-family:  'Nunito, Mada-Regular, sans-serif'
}

.editor-paragraph {
	margin-top: 0;
	margin-bottom: 0;
	font-family:  'Nunito, Mada-Regular, sans-serif'
}